import asd from './components/ex.js'
import slider from './components/slider.js';

const burger = document.querySelector('.header__burger__btn');
const burgerLine = document.querySelectorAll('.line');
const headerBurgerMenu = document.querySelector('.header__burger__menu')

document.getElementById("triggger").addEventListener("click", () => {
   Fancybox.show([{ src: "#dialog-content", type: "inline" }]);
 });

 document.getElementById("stop").addEventListener("click", (e) => {
   e.stopPropagation();
 });
 Fancybox.bind('[data-fancybox]', {
   //
 });

 Fancybox.bind('[data-fancybox="gallery"]', {
   //
 }); 
 Fancybox.bind('[data-fancybox="gallery-2"]', {
   //
 }); 

asd();

slider();

burger.addEventListener('click', () => {
   burgerLine.forEach((e) => {
      e.classList.toggle('act');
   })
   headerBurgerMenu.classList.toggle('activ');
});